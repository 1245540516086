* {
    margin: 0;
    padding: 0;
}

body {
    background-color: $light;
    overflow-x: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    cursor: pointer;
}

.navigation__list,
.main__heading,
.footer__links,
.footer__logos,
.footer__heading {
    margin-top: 10px;
}


.header__icon,
.header__dropdown,
.main__bannerBg,
.main__banner,
.article__image,
.footer__FAJ,
.footer__UMK {
    background-repeat: no-repeat;
    background-size: cover;
}

.main__heading,
.article__paragraph {
    text-align: center;
}