.footer {
    @include FlexCenter;

    background-color: $dark;
    margin-top: 20px;
    padding: 5px;
    flex-direction: column;
    color: $white;

    @include mq(large) {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 20px 10px;
    }

    @include mq(xlarge) {
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 100px 10px;
    }

    &__heading {
        font-size: 1.1rem;
        font-weight: normal;
        margin-bottom: 10px;
    }

    &__heading--nomargin {
        margin: 0;
    }

    &__links,
    &__logos,
    &__contacts {
        @include FlexCenter;

        @include mq(large) {
            width: 33%;
        }

        @include mq(xlarge) {
            width: 33%;
        }
        flex-direction: column;
    }

    &__logos {
        @include mq(large) {
            flex-direction: row;
            justify-content: center;
        }
    }

    .links__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__paragraph {
        margin: 5px 0 10px;
    }

    &__FAJ {
        background-image: url('../images/faj.png');
        width: 150px;
        height: 69px;

        @include mq(large) {
            width: 120px;
            height: 55px;
        }

        @include mq(xlarge) {
            width: 150px;
            height: 69px;
        }
    }

    &__UMK {
        background-image: url('../images/umk.png');
        width: 202px;
        height: 60px;
        margin-top: 10px;

        @include mq(large) {
            width: 150px;
            height: 45px;
            margin-top: 0;
            margin-left: 20px;
        }

        @include mq(xlarge) {
            width: 202px;
            height: 60px;
        }
    }

    a,
    a:active,
    a:visited {
        text-decoration: none;
        color: $orange;
    }

    &__highlighted {
        color: $orange !important;
        font-weight: bold;
    }

    &__link--block {
        display: block;
    }
}