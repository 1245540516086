.article {
    @include FlexCenter;

    background-color: $white;
    flex-direction: column;
    margin: 20px 0 20px;
    padding: 50px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);


    &__section {
        //@include FlexCenter;
        text-align: justify;
        width: 100%;

        p {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        ul {
            margin-left: 50px;
        }

        li {
            margin: 10px 0px 10px 0px;
        }

        img {
            width: 80%;
            max-width: 400px;
            border-radius: 3px;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
            margin: auto;
            display: block;
            margin-bottom: 50px;
            margin-top: 50px;
        }

        .partners {
            img{
                border: none;
                box-shadow: none;
            }
        }
    }

    &__item {
        margin: 10px 0 10px;
    }

    &__paragraph {
        width: 90%;
        font-size: 1.1rem;

        @include mq(medium) {
            text-align: left;
        }
    }

    &__header {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    &__image {
        background-image: url('../images/right.png');
        height: 300px;
        background-position: center;
        border-radius: 3px;
        width: 75%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.5);


        @include mq(small) {
            width: 70%;
        }

        @include mq(medium) {
            width: 40%;
        }

        @include mq(large) {
            height: 400px;
        }
    }

    &__form {
        @import '/node_modules/bootstrap/scss/bootstrap';

        div {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        fieldset {
            margin-top: 25px;
        }

        legend {
            margin-bottom: 15px;
        }

        button[type="submit"] {
            margin: auto;
            margin-top: 25px;
        }

        .form_invisible {
            display: none;
        }

        .form_visible {
            display: block;
        }

        .invalid {
            border: 1px solid red;
        }
    }

    &__image--change {
        background-image: url('../images/left.png');
        height: 400px;
        background-position: center;
    }

    &__image--torun {
        background-image: url('../images/instytut.jpg');
        background-size: contain;
    }

    &__image--hidden {
        display: none;

        @include mq(medium) {
            display: block;
        }
    }

}