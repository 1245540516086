.navigation {
    @include FlexCenter;

    height: max-content;
    transition: .2s .1s ease-in-out;
    overflow: hidden;
    

    @include mq(large) {
        width: 80%;
        justify-content: flex-end;
    }

    &__item {
        padding: 2px;
        font-size: 1.5em;

        @include mq(large) {
            font-size: 1.0rem;
        }

        @include mq(xlarge) {
            font-size: 1.15rem;
        }

    }

    &__link {
        text-decoration: none;
        color: $white;
    }

    &__link:hover {
        color: $orange;
    }

    &__list {
        @include FlexCenter;

        flex-direction: column;
        align-items: center;
        list-style-type: none;

        @include mq(large) {
            width: 100%;
            max-width: 1200px;
            flex-direction: row;
            gap: 20px;
            text-align: center;
            justify-content: space-around;
            margin: 0;
            font-size: 1.2em;
        }

        @include mq(small){
            gap: 30px;
        }

        @include mq(medium){
            gap: 30px;
        }
    }

    &--hidden {
        height: 0;
        overflow: hidden;

        @include mq(large) {
            height: 100%;
        }
    }
}
