.header {
    background-color: $dark;
    padding: 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;

    @include mq(large) {
        @include FlexCenter;

        justify-content: space-between;
        padding: 20px 20px 20px;
    }

    &__icons {
        @include FlexCenter;
        justify-content: space-between;
    }

    &__dropdown,
    &__icon {
        height: 40px;
        width: 117px;
    }

    &__icon {
        background-image: url('../images/banner.png');
        background-size: contain;
        @include mq(large) {
            width: 125px;
            height: 47px;
        }
    }

    &__dropdown {
        background-image: url('../images/dropdown.png');
        background-size: contain;

        @include mq(large) {
            display: none;
        }
    }
}