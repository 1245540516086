#container {
    @include FlexCenter;
    flex: 1;
}

.main {
    @include FlexCenter;

    flex-direction: column;
    width: 100%;

    &__bannerBg {
        @include FlexCenter;

        background-image: url('../images/bannerBg.jpg');
        width: 100vw;
        height: 200px;
        margin-bottom: 20px;

        @include mq(small) {
            height: 400px;
        }

        @include mq(xlarge) {
            height: 600px;
        }
    }

    &__banner {
        background-image: url('../images/banner.png');
        background-size: contain;
        width: 252.5px;
        height: 50%;

        @include mq(small) {
            width: 505px;
        }

        @include mq(xlarge) {
            width: 757.5px;
        }
    }

    &__heading {
        font-weight: bold;
        width: 100%;
        font-size: 1.4rem;
        text-align: left;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    &__heading--big {
        font-size: 1.6rem;
        text-align: center;
        margin: 0;
        padding: 25px;
        padding-top: 0px;
    }

    &__wrapper {
        @include FlexCenter;

        width: 80%;
        flex-direction: column;

        @include mq(xlarge) {
            max-width: 1200px;
        }
    }

    &__button {
        margin: 0 auto;
        width: 200px;
        height: 40px;
        border: none;
        background-color: $orange;
        font-size: 1.25rem;
        border-radius: 10px;
        color: $white;

        @include mq(large) {
            display: none;
        }
    }
}